import { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { sync } from 'redux/Storage/actions'

export default () => {
  const dispatch = useDispatch()

  const listener = (event) => {
    if (event.newValue && event.newValue !== '1') {
      const value = JSON.parse(event.newValue)
      dispatch(sync(value))
    }
  }

  const listenerCallback = useCallback(listener, [dispatch])

  useEffect(() => {
    window.addEventListener('storage', listenerCallback, false)

    return () => window.removeEventListener('storage', listenerCallback)
  }, [listenerCallback])
}
